import { useQuery } from '@tanstack/react-query'
import { axiosAPI } from '../axiosAPI.js'

const useQueryMockFeedback = (gymID, pageNum, itemsPerPage, wallFilterList, gradeRanges, omitBroken, sortObj) => {
    const { isLoading, error, data, refetch } = useQuery({
        queryKey: ['tryit-feedback'],
        queryFn: async () => {
          const parameters = {
            pageNum: pageNum,
            itemsPerPage, itemsPerPage,
            wallFilterList: wallFilterList,
            gradeRanges: gradeRanges,
            omitBroken: omitBroken,
            sortObj: sortObj
          }
          const response = await axiosAPI.post('tryit-feedback/' + gymID, parameters)

          return response.data
        },
        retry: 1,
    })

    return { data, isLoading, error, refetch }
}

export default useQueryMockFeedback;