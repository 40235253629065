import { useState, useEffect } from 'react'
import { CircleX } from "lucide-react"
import UseMutateAdminUpdateWallSet from '../../../api/Gym/UseMutateAdminUpdateWallSet.js'  
import { Input } from '../../../components/ui/input'
import { Label } from '../../../components/ui/label'
import { Button } from '../../../components/ui/button.jsx'
  import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
    CardFooter
  } from "../../../components/ui/card.jsx"

export function EditWallSet({modalVis, setModalVis, gymData, gymId, wallId, wallSetId, setWallSetId}) {  
    const [ form, setForm ] = useState(null)

    useEffect(() => {
        if (gymData !== null && gymId !== null && wallId !== null && wallSetId !== null) {
            const wallIdx = gymData.walls.findIndex((wall) => wall._id == wallId)
            const wallSetIdx = gymData.walls[wallIdx].wallSets.findIndex((wallSet) => wallSet._id == wallSetId)

            if (wallSetIdx == -1) {
                setWallSetId(null)
                setModalVis(false)
            } else {
                let switchSetValue
                if (gymData.walls[wallIdx].currentWallSetID == gymData.walls[wallIdx].wallSets[wallSetIdx]._id) {
                    switchSetValue = true
                } else {
                    switchSetValue = false
                }
    
                setForm({...gymData.walls[wallIdx].wallSets[wallSetIdx], switchSets: switchSetValue})
            }       
        }
        
    }, [gymData, gymId, wallId, wallSetId])

    if (modalVis) {
        return (
            <div className="fixed top-0 h-full w-full z-20 bg-black/10">
                <Card className="mt-12 mx-auto w-[18rem] h-fit box-border rounded-lg p-1 overflow-auto">
                    <CardHeader >
                        <div className="flex flex-row justify-between pb-2">
                        <CardTitle className="w-fit">Edit Wall Set</CardTitle>     
                        <CircleX 
                            color="red"
                            className="h-4 w-4"
                            onClick={() => setModalVis(false)}
                        />  
                        </div>                                                                                                          
                    </CardHeader>
                    <CardContent>                
                    </CardContent>
                    { form !== null ?
                        <CardContent className="p-4 pt-2">
                            <Label
                                className="w-24"
                                htmlFor="wallSet">Wall Set {" "}</Label>
                            <Input
                            id="date" 
                            name="date"
                            required
                            disabled
                            type="text"
                            className="w-full"
                            placeholder="Enter a wall name"
                            value={form.date}
                            />
                            <Label
                                className="w-24"
                                htmlFor="wallSetURL">Wall Set URL {" "}</Label>
                            <Input
                            id="wallSetURL" 
                            name="wallSetURL"
                            required
                            type="text"
                            className="w-full"
                            placeholder="Enter a wall set URL"
                            value={form.wallSetURL}
                            onChange={(e) => setForm({...form, wallSetURL: e.target.value})}
                            />
                            <Label
                                className="w-24"
                                htmlFor="wallGeometry">Wall Set Geometry {" "}</Label>
                            <Input
                            id="wallGeometry" 
                            name="wallGeometry"
                            required
                            type="text"
                            className="w-full"
                            placeholder="Enter the name of the geometry"
                            value={form.wallGeometry}
                            onChange={(e) => setForm({...form, wallGeometry: e.target.value})}
                            />
                            <Label
                                className="w-24"
                                htmlFor="wallGeometry">Wall Set Material {" "}</Label>
                            <Input
                            id="wallMaterial" 
                            name="wallMaterial"
                            required
                            type="text"
                            className="w-full"
                            placeholder="Enter the name of the material"
                            value={form.wallMaterial}
                            onChange={(e) => setForm({...form, wallMaterial: e.target.value})}
                            />
                            <div className="flex flex-row justify-start mt-4">
                                <Input 
                                    type="checkbox"                             
                                    onClick={() => {setForm({...form, switchSets: form.switchSets == false ? true : false})}} 
                                    name="showInfo" 
                                    checked={form.switchSets}
                                    className="w-4 h-auto" />
                                <Label className="text-nowrap ml-2">Pin this wall set for this wall?</Label>
                            </div>  
                        </CardContent>  
                    :
                        null
                    }   
                    
                    <CardFooter className="flex flex-row justify-around">
                        <UseMutateAdminUpdateWallSet 
                            gymId={gymId}
                            wallId={wallId}
                            updatedWallSet={form} 
                            setModalVis={setModalVis} /> 
                        <Button 
                            size="sm"
                            variant="destructive"
                            onClick={() => setModalVis(false)}>
                            Cancel
                        </Button>
                    </CardFooter> 
                </Card>
            </div>            
        )
    } else return
    
}