import { useState } from 'react'
import Loading from '../Site/Loading.js'
import PageIsPrivate from './PageIsPrivate.js'
import PageNotFound from '../Site/PageNotFound.js'
import { daysOfWeek } from '../helpers/daysOfOurLives.js'
import useQueryExploreGyms from '../api/Gym/useQueryExploreGyms.js'
import { ExploreCards } from '../blocks/components/data/ExploreCards.js'

import { Button } from '../components/ui/button'
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
  } from "../components/ui/card.jsx"
import '../tailwind.css'
import './beta.css'


const ExploreGyms = () => {
    const [ today, setToday ] = useState({})
    const { isLoading, error, data } = useQueryExploreGyms(daysOfWeek, setToday)

    if (isLoading) return <Loading />

    if (error) {
        if (error.response.data.error == "private") {
            return <PageIsPrivate errMessage={error.response.data.message} />
        } else {
            return <PageNotFound />
        }
    } 

    return (
        <>
        <div className="flex min-h-screen w-full flex-col bg-muted/40">
            <div className="flex flex-col sm:gap-4 sm:py-4 sm:pl-4">
                <main className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
                <div>
                    <Card x-chunk="dashboard-06-chunk-0">
                        <CardHeader >
                        <CardTitle>Explore Gyms</CardTitle>
                        <CardDescription>
                            Search through climbing gyms!
                        </CardDescription>
                        </CardHeader>
                        <CardContent className="h-screen w-screen flex flex-col justify-start items-center overflow-auto sm:flex-row sm:items-start sm:flex-wrap"> 
                            <ExploreCards
                                exploreGyms={data}
                                today={today} />
                            <ExploreCards
                                exploreGyms={data}
                                today={today} />
                            <ExploreCards
                                exploreGyms={data}
                                today={today} />                                                            
                        </CardContent>                        
                    </Card>
                </div>
                </main>
            </div>
        </div>        
        </>
        
    )
}

export default ExploreGyms;