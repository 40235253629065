import * as React from "react"
import {
  Card,
  CardDescription,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card"


export function CardInfo({heading, subtext, linkButton}) {
  return (
    <Card className="w-60 h-56 mt-4 mx-4 flex-col justify-between sm:min-w-48 sm:min-h-64">
      <CardHeader>
        <CardTitle style={{ height: "4.5rem" }}>{heading}</CardTitle>
      </CardHeader>
      <CardContent>
        <CardDescription>{subtext}</CardDescription>
      </CardContent>
    </Card>
  )
}
