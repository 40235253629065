"use client"

import { useState, useEffect } from 'react';
import { Bar, BarChart, CartesianGrid, XAxis, YAxis, LabelList } from "recharts"

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card"
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../../components/ui/chart"

export function GymBarGrades({ gymName, chartData }) {
  const xAxisKey = "grade"
  const yAxisKey = "count"
  const [ labelConfig, setLabelConfig ] = useState({})

  useEffect(() => {
    let newLabels = { 
      count: {
        label: "Count",
      }
    }
    
    chartData.map((item) => {
      newLabels[item.grade] = { label: item.grade}
    })

    setLabelConfig(newLabels)
  }, [chartData])

  return (
    <Card className="sm:col-span-4 md:col-span-2 lg:col-span-2">
      <CardHeader>
        <CardTitle>Grades Distribution</CardTitle>
        <CardDescription>{gymName}</CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer config={labelConfig}>
          <BarChart
            accessibilityLayer
            data={chartData}
            margin={{
              top: 20,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey={xAxisKey}
              type="category"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              tickFormatter={(value) => labelConfig[value].label}
            />
            <YAxis dataKey={yAxisKey} type="number" hide />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
            <Bar dataKey={yAxisKey} layout="vertical" radius={5} >
              <LabelList
                  position="top"
                  offset={12}
                  className="fill-foreground"
                  fontSize={12}
                />
            </Bar>
          </BarChart>
        </ChartContainer>
      </CardContent>      
    </Card>
  )
}
