import { useQuery } from '@tanstack/react-query'
import { axiosAPI } from '../axiosAPI.js'

const useQueryMockGyms = () => {
    const { isLoading, error, data } = useQuery({
        queryKey: ['tryit-gyms'],
        queryFn: async () => {
            const response = await axiosAPI.get('tryit-gym-data')

            return response.data
        },
        enabled: true,
        retry: 1,
    })

   return { isLoading, error, data}
}

export default useQueryMockGyms;