import { useState } from 'react'
import {Link} from "react-router-dom"
import useQueryUserClimbs from '../api/Climb/useQueryUserClimbs.js'
import Loading from '../Site/Loading.js'
import PageNotFound from '../Site/PageNotFound.js'
import { Button } from "../components/ui/button"
import { Separator } from "../components/ui/separator"
import { ButtonLink } from '../blocks/components/buttons/ButtonLink'
import { GymTable } from '../blocks/components/data/GymTable'
import { GymFollowing } from '../blocks/components/data/GymFollowing'
import { GymBarGrades } from '../blocks/components/data/GymBarGrades'
import { GymRadarFeedback } from '../blocks/components/data/GymRadarFeedback'
import {
  Activity,
  ArrowUpRight,
  CreditCard,
  LineChart
} from "lucide-react"

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../components/ui/card"

const barData = [
  { grade: "VB-0", count: 17, fill: "#ffee00" },
  { grade: "V1", count: 24, fill: "#fa077d" },
  { grade: "V2-3", count: 38, fill: "#072ffa" },
  { grade: "V3-4", count: 42, fill: "#00e208" },
  { grade: "V4-6", count: 21, fill: "#fa6c07" },
  { grade: "V6-8", count: 11, fill: "#fa0707" },
  { grade: "V8+", count: 9, fill:  "#000000" },
]

const radarLegend = "rating"
const radarValue = "count"
const radarData = [
  { rating: "Perfect", count: 237 },
  { rating: "Easy", count: 273 },
  { rating: "VeryEasy", count: 209 },
  { rating: "Very Hard", count: 186 },
  { rating: "Hard", count: 305 },
]

const radarConfig = {
  count: {
    label: "Count",
    color: "hsl(var(--chart-1))",
  },
}

const headings = ["#", "Gym", "Feedback"]

const dayOfTheWeek = "SAT"
const followingGyms = [
  {
    gymName: "Rock Climbing Gym",
    hours: "10:00AM to 10:00PM",
    id: "6655628aaedf840be6767df7"
  },
  {
    gymName: "The Ultimate Climbing Gym",
    hours: "9:00AM to 5:00PM",
    id: "66e305d1ac9b4683378cdfc3"
  },
  {
    gymName: "A Pump Climbing Gym",
    hours: "12:00PM to 8:00PM",
    id: "Bop66eb9edebe85e3e45f0a20e6pydo123"
  },
  {
    gymName: "Rock Climbing Gym",
    hours: "10:00AM to 10:00PM",
    id: "6655628aaedf840be6767df7"
  },
  {
    gymName: "The Ultimate Climbing Gym",
    hours: "9:00AM to 5:00PM",
    id: "66e305d1ac9b4683378cdfc3"
  },
  {
    gymName: "A Pump Climbing Gym",
    hours: "12:00PM to 8:00PM",
    id: "Bop66eb9edebe85e3e45f0a20e6pydo123"
  },  {
    gymName: "Rock Climbing Gym",
    hours: "10:00AM to 10:00PM",
    id: "6655628aaedf840be6767df7"
  },
  {
    gymName: "The Ultimate Climbing Gym",
    hours: "9:00AM to 5:00PM",
    id: "66e305d1ac9b4683378cdfc3"
  },
  {
    gymName: "A Pump Climbing Gym",
    hours: "12:00PM to 8:00PM",
    id: "Bop66eb9edebe85e3e45f0a20e6pydo123"
  },
]

const Dashboard = () => {
  const [ userId, setUserId ] = useState(sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : '')
  const { isLoading, error, data } = useQueryUserClimbs()

  if (isLoading) return <Loading />

  if (error) return <PageNotFound />
  
  return (
    <div className="flex flex-col w-full h-screen overflow-auto">    
      <main className="flex flex-1 flex-col h-fit gap-4 p-4 md:gap-8 md:p-8">
        <div className="grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-4">          
          {data.userGyms.map((gym) => {
            return <>
              <Card className="sm:col-span-4">
                <CardHeader className="flex flex-row justify-between items-center">
                  <CardTitle>
                    {gym.gymName}
                  </CardTitle>
                  <CardDescription className="flex w-36 justify-between"> 
                    <ButtonLink link={"/gym/" + gym._id} buttonName={"Go to Gym!"} />
                 
                    <ButtonLink link={"/feedback/" + gym._id} buttonName={<LineChart className="h-3.5 w-3.5" />} />
                  </CardDescription>
                </CardHeader>
              </Card>
              <Card className="sm:col-span-4 md:col-span-2 lg:col-span-2" x-chunk="dashboard-01-chunk-2">
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">Newest Wall</CardTitle>
                  <CreditCard className="h-4 w-4 text-muted-foreground" />
                </CardHeader>
                <CardContent>
                  <div className="text-2xl font-bold">Back of Dragon</div>
                  <p className="text-xs text-muted-foreground">
                    2nd Half
                  </p>
                </CardContent>
              </Card>
              <Card className="sm:col-span-4 md:col-span-2 lg:col-span-2" x-chunk="dashboard-01-chunk-3">
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">Next Wall Set</CardTitle>
                  <Activity className="h-4 w-4 text-muted-foreground" />
                </CardHeader>
                <CardContent>
                  <div className="text-2xl font-bold">Cave</div>
                  <p className="text-xs text-muted-foreground">
                    Next week, Wednesday
                  </p>
                </CardContent>
              </Card>
              <GymBarGrades 
                gymName={gym.gymName}
                chartData={gym.gradeDistribution} />
              <GymRadarFeedback 
                chartData={radarData} 
                chartConfig={radarConfig} 
                legendType={radarLegend} 
                valueType={radarValue}/>
              <Separator className="my-4 sm:col-span-4" />
            </>          
          })}        
          {/* <Card
              className="col-span-4" 
              x-chunk="dashboard-01-chunk-5">
            <CardHeader>
              <CardTitle>Gyms you follow</CardTitle>
            </CardHeader>
            <CardContent className="h-72 overflow-auto grid gap-8">
              <GymFollowing 
                following={followingGyms}
                dOTW={dayOfTheWeek} />
            </CardContent>
          </Card> */}
        </div>        
      </main>
    </div>
  )
}

export default Dashboard