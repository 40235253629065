import { useState } from 'react'
import Page404 from "../assets/img/404.png";
import {
  Card,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/ui/card"
import { ButtonLink } from "../blocks/components/buttons/ButtonLink"

const PageNotFound = () => {
    const [ userId, setUserId ] = useState(sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : null)

    return (
        <div className="flex min-h-screen w-full flex-col bg-muted/40">
            <Card className="w-72 mx-auto my-12">
                <CardHeader>
                    <img 
                        src={Page404}
                        className="mb-4" />
                    <CardTitle className="text-2xl text-center">Oops! Page Not Found.</CardTitle>
                    <CardDescription className="text-center">
                    The page you are looking for is not recognized. Try a different page or go to the homepage with the button below.
                    </CardDescription>
                </CardHeader>
                <CardFooter className="flex flex-row justify-center">
                    { userId !== null ?
                        <ButtonLink link={"/dashboard"} buttonName={"Home"} />
                    :
                        <ButtonLink link={"/"} buttonName={"Home"} />
                    }
                </CardFooter>
            </Card>
        </div>        
    )
}

export default PageNotFound