import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Pencil, Trash2 } from 'lucide-react'
import useMutateAdminDeleteWallset from '../api/Gym/useMutateAdminDeleteWallset'
import Loading from '../Site/Loading.js'
import PageIsPrivate from './PageIsPrivate.js'
import PageNotFound from '../Site/PageNotFound.js'
import {AddWall} from '../blocks/components/data/AddWall.js'
import {EditWall} from '../blocks/components/data/EditWall.js'
import {AddWallSet} from '../blocks/components/data/AddWallSet.js'
import {EditWallSet} from '../blocks/components/data/EditWallSet.js'
import { AlertModal } from '../blocks/components/alerts/AlertModal.js'
import useQueryGymWallSets from '../api/Gym/useQueryGymWallSets'
import { ScrollArea } from '../components/ui/scroll-area.jsx'
import { Separator } from '../components/ui/separator.jsx'
import { Button } from '../components/ui/button.jsx'
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
  } from "../components/ui/card.jsx"
  import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
  } from "../components/ui/breadcrumb"  

const ListWallSetsGym = () => {
    const { id } = useParams()
    const [ addWallModal, setAddWallModal ] = useState(false)
    const [ editWallModal, setEditWallModal ] = useState(false)
    const [ addSetModal, setAddSetModal ] = useState(false)
    const [ editSetModal, setEditSetModal ] = useState(false)
    const [ deleteSetModal, setDeleteSetModal ] = useState(false)
    const [ wallId, setWallId ] = useState(null)
    const [ wallSetId, setWallSetId ] = useState(null)
    const [ editWallName, setEditWallName ] = useState(null)
    const [ newWallSet, setNewWallSet ] = useState(null)

    const [ userId, setUserId ] = useState(sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : '')
    const { data, isLoading, error } = useQueryGymWallSets(id, setUserId)

    const { onSubmitDeleteWallset } = useMutateAdminDeleteWallset(id, wallId, wallSetId, setDeleteSetModal)


    if (isLoading) return <Loading />

    if (error) {
        if (error.response.data.error == "private") {
            return <PageIsPrivate errMessage={error.response.data.message} />
        } else {
            return <PageNotFound />
        }
    } 

    return <>
        <div className="min-h-screen w-full p-4 flex-col bg-muted/40">
            <AddWall
                modalVis={addWallModal}
                setModalVis={setAddWallModal}
                gymId={id} />
            <EditWall
                modalVis={editWallModal}
                setModalVis={setEditWallModal}
                gymId={id}
                wallId={wallId}
                editWallName={editWallName}
                setEditWallName={setEditWallName} />
            <AddWallSet 
                modalVis={addSetModal}
                setModalVis={setAddSetModal}
                gymData={data}
                gymId={id}
                wallId={wallId == null ? '' : wallId}
                setWallId={setWallId}
                newWallSet={newWallSet}
                setNewWallSet={setNewWallSet} />
            <EditWallSet
                modalVis={editSetModal}
                setModalVis={setEditSetModal}
                gymData={data}
                gymId={id}
                wallId={wallId}
                wallSetId={wallSetId} 
                setWallSetId={setWallSetId}/>
            <AlertModal 
                modalVis={deleteSetModal}
                setModalVis={setDeleteSetModal}
                heading={"Delete this wall set?"}
                subHeading={"This action cannot be undone. This will permanently delete the wall set."} 
                submitFunction={(e) => onSubmitDeleteWallset(e)} 
            />  
            <header className="sticky top-0 z-30 flex h-14 items-center gap-4 border-b bg-background px-4 sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6">          
                <Breadcrumb className="flex">
                <BreadcrumbList>
                    <BreadcrumbItem>
                    <BreadcrumbLink asChild>
                        <Link to={{ pathname: "/admin" }}>Admin</Link>
                    </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />              
                    <BreadcrumbItem>
                    <BreadcrumbPage>List Walls and Wall Sets</BreadcrumbPage>
                    </BreadcrumbItem>
                </BreadcrumbList>
                </Breadcrumb>          
            </header>         
            <Card   
                className="mt-4 w-80 mx-auto"              
                x-chunk="dashboard-06-chunk-0">
                <CardHeader>
                <CardTitle>List Walls and Wall Sets</CardTitle>
                <CardDescription>
                    Options to add, edit, delete, and move wall sets.
                </CardDescription>
                <Button
                    size="sm"
                    className="px-2"
                    onClick={() => setAddWallModal(true)}>
                    + Wall
                </Button>
                <Button
                    size="sm"
                    className="px-2"
                    style={{marginTop: "0.5rem"}}
                    onClick={() => setAddSetModal(true)}>
                    + Wall Set
                </Button>
                </CardHeader>
                <CardContent>
                    {data.walls.map((wall) => {
                        return <Card className="mb-4">
                            <CardHeader className="flex flex-row items-center justify-between">
                                <CardTitle>{wall.wallName}</CardTitle>
                                <Pencil 
                                className="w-5 h-5"
                                onClick={() => {setEditWallName(wall.wallName); setWallId(wall._id); setEditWallModal(true)}}/>
                            </CardHeader>
                            <CardContent>
                                <ScrollArea className="h-48 w-48 rounded-md border">
                                    <div className="p-4">
                                        <h4 className="mb-4 text-sm font-medium leading-none">Wall Sets</h4>
                                        {wall.wallSets.map((wallSet) => {
                                            const wallSetDate = new Date(Date.parse(wallSet.date)).toLocaleDateString()
                                            return <>
                                            <div className="flex flex-row justify-between">
                                                <p className="text-xs">
                                                    { wallSetDate }
                                                </p>
                                                <div className="flex flex-row w-12 justify-between">
                                                    <Pencil
                                                        className="w-4 h-4"
                                                        onClick={() => {setWallId(wall._id); setWallSetId(wallSet._id); setEditSetModal(true)}} />
                                                    <Trash2 
                                                        className="w-4 h-4"
                                                        color="red"
                                                        onClick={() => {setWallId(wall._id); setWallSetId(wallSet._id); setDeleteSetModal(true)}}/>
                                                </div>                                            
                                            </div>
                                            <Separator className="my-2" />
                                            </>
                                        })}                                                                                                                                                                                        
                                    </div>
                                </ScrollArea>                                
                            </CardContent>
                        </Card>
                    })}
                </CardContent>
            </Card>
        </div>
    </>
}

export default ListWallSetsGym