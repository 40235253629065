import { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { BookCheck, BookUp } from "lucide-react"
import AppLogo from '../../assets/img/beta-break.png'
import { daysOfWeek } from '../../helpers/daysOfOurLives.js'
import { Button } from '../../components/ui/button'
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
  } from "../../components/ui/card.jsx"
import { Input } from "../../components/ui/input.jsx"
import { Label } from "../../components/ui/label.jsx"
import { Badge } from "../../components/ui/badge.jsx"

const ModalGymIntro = ({modalVis, setModalVis, gymInfoCookie, handleSetGymInfoCookie}) => {
     const queryClient = useQueryClient()
    const gym = queryClient.getQueryData(['gym'])

    const [ userId, setUserId ] = useState(sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : '')

    const updateIntroAlert = () => {
        setModalVis(false)
    }

    if (modalVis) {
        return (
            <div className="fixed top-0 h-full w-full z-20 bg-black/10">
                <Card className="mt-12 mx-auto w-[18rem] h-[35rem] box-border rounded-lg p-1 overflow-auto">
                    <CardHeader className="flex flex-row justify-between pb-2">
                        <CardTitle className="w-28">{gym.gymName}</CardTitle>
                        <img src={AppLogo} className="w-6 h-10"></img>
                        <Button 
                            size="icon" 
                            variant="destructive" 
                            onClick={() => updateIntroAlert()}
                            className="h-6 w-6 mt-0">x</Button>   
                    </CardHeader>
                    <CardContent>
                        <CardDescription className="flex flex-row justify-start">
                            <Input 
                                type="checkbox" 
                                onClick={() => {handleSetGymInfoCookie()}} 
                                name="showInfo" 
                                checked={gymInfoCookie == true ? false : true}
                                className="w-4 h-auto" />
                            <Label className="text-nowrap ml-2">Do not show on page reload?</Label>
                        </CardDescription> 
                        <div className="pt-4">
                            <Label className="text-md font-bold">Open Times</Label>
                            <CardContent className="p-2 pt-0">
                            { gym.openHours.map((timeSlot, idx) => {
                                return (
                                    <div key={timeSlot + "-" + idx} className="flex flex-row justify-start items-center my-2">
                                        <Badge variant="secondary" className="w-12 font-semibold justify-center">
                                            {daysOfWeek[idx]}
                                        </Badge>
                                        <p key={idx + "-" + timeSlot}
                                        className="text-xs ml-1" >                                    
                                            
                                            {"- " + timeSlot}                              
                                        </p>
                                    </div>
                                )
                            })}
                            </CardContent>
                        </div>
                        <div>
                            <Label className="text-md">Address</Label>
                            <CardContent className="p-2 pt-0">
                                    <p className="text-sm mb-2">                                                                            
                                        {gym.location.address}                            
                                    </p>
                                    <p className="text-sm mb-2">                                                                            
                                        {gym.location.city + ", " + gym.location.province}
                                    </p>
                                    <p className="text-sm mb-2">                                                                            
                                        {gym.location.postalCode}
                                    </p>
                            </CardContent>
                        </div>
                        <div>
                            <Label className="text-md">Grading System</Label>
                            <CardContent className="p-2 pt-0 flex flex-row flex-wrap justify-start">
                            { gym.gradingType == "color" ?
                                gym.gradingSystem.map((grade, idx) => {
                                    return (
                                        <div
                                            key={grade.grade + "-" + idx}
                                            className="mx-2 w-10 mb-4">
                                            <Badge 
                                                style={{ backgroundColor: grade.color}}
                                                className="w-10 h-4 rounded-none" />
                                            <p className="text-xs text-left font-bold text-center">{grade.grade}</p>                           
                                        </div>
                                    )
                                    })
                                :
                                gym.gradingType == "text" ?
                                    gym.gradingSystem.map((grade, idx) => {
                                        return (
                                            <Badge
                                                className="w-16 p-2 m-2 h-4 rounded flex flex-row justify-center">
                                                <p className="text-xs text-left font-bold text-center">{grade.grade}</p> 
                                            </Badge>                          
                                        )
                                    })
                            :
                                null
                            }
                            </CardContent>
                        </div>
                        <div>
                            <Label className="text-md">Most Recently Set Wall</Label>
                            <CardContent className="p-2 pt-0 flex flex-row justify-start">
                                <BookCheck />
                                <p className="text-sm ml-1 mb-2">                                                                            
                                    {gym.newWallSet.name}
                                </p>
                            </CardContent>
                        </div>
                        <div>
                            <Label className="text-md">Next Wall To Update</Label>
                            <CardContent className="p-2 pt-0 flex flex-row justify-start">
                                <BookUp />
                                <p className="text-sm ml-1 mb-2">                                                                            
                                    {gym.nextWallToSet}
                                </p>
                            </CardContent>
                        </div>
                    </CardContent>
                </Card> 
            </div>
        )
    } else return
    
}

export default ModalGymIntro