import { ButtonLink } from '../blocks/components/buttons/ButtonLink'

const HowItWorks = () => {
    return (
        <div className="container max-w-full mt-8">
          <h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl">
              How It Works
          </h1>
          <p className="leading-7 [&:not(:first-child)]:mt-6">
              The process is as follows,
          </p>        
          <ol className="my-4 ml-6 list-decimal">
            <li className="mt-2 text-sm">Consultation Meeting</li>
            <blockquote className="mt-2 border-l-2 pl-6 text-sm italic">
              We want to learn more about your gym, the unique challenges it faces, share beta-break features, and how beta-break can assist you.
            </blockquote>    
            <li className="mt-2 text-sm">Setting up your gym</li>
            <blockquote className="mt-2 border-l-2 pl-6 text-sm italic">
              Your account, any user accounts for staff members, and the gym within beta-break will be setup.
            </blockquote> 
            <li className="mt-2 text-sm">Gym Wall Setup</li>
            <blockquote className="mt-2 border-l-2 pl-6 text-sm italic">
              In visiting your gym, 3D models will be constructed of your current walls, holds, and routes
            </blockquote> 
            <li className="mt-2 text-sm">Onboarding Training</li>
            <blockquote className="mt-2 border-l-2 pl-6 text-sm italic">
              We will meet to guide you through using beta-break, answer any questions, and customize any minor changes you wish to adapt for your gym.
            </blockquote> 
            <li className="mt-2 text-sm">[Ongoing] Gym Wall Collection</li>
            <blockquote className="mt-2 border-l-2 pl-6 text-sm italic">
              At the frequency we outline in our consultation meeting, we will visit the gym to construct new 3D models of newly set walls, holds, and routes.
            </blockquote> 
            <li className="mt-2 text-sm">[Ongoing] Feedback</li>
            <blockquote className="mt-2 border-l-2 pl-6 text-sm italic">
              If there are ways we can improve to enhance your offering to your members in using beta-break we would love to hear it.
            </blockquote> 
          </ol>         
          <div className="my-6 w-full overflow-y-auto">            
            <div className="flex flex-row justify-around mt-4">                
                <ButtonLink
                    link={"/"}
                    buttonName={"Home"}
                />
            </div>                    
          </div>   
        </div>
      )
    

                
}

export default HowItWorks
  