import { Routes, Route } from 'react-router-dom';
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import history from '../src/api/history.js';

// Site
import Header from './Site/Header.js'
import Footer from './Site/Footer.js'
import Home from './Site/Home.js'
import About from './Site/About.js'
import HowItWorks from './Site/HowItWorks.js'
import Blog from './Site/Blog.js'
import Policies from './Site/Policies.js'
import Login from './Site/Login.js'
import Signup from './Site/Signup.js'
import Profile from './Site/Profile.js'
import ForgotPassword from './Site/ForgotPassword.js'
import ResetPassword from './Site/ResetPassword.js'
import ContactUs from './Site/ContactUs.js'
import PageNotFound from './Site/PageNotFound.js'

// Project
import TryDashboard from './Beta/TryDashboard.js'
import TryFeedback from './Beta/TryFeedback.js'
import Dashboard from './Beta/Dashboard.js'
import GymCanvas from './Beta/GymCanvas.js'
import Feedback from './Beta/Feedback.js'
import AdminGyms from './Beta/AdminGyms.js'
import CreateGym from './Beta/CreateGym.js'
import EditGym from './Beta/EditGym.js'
import ListWallSetsGym from './Beta/ListWallSetsGym.js'
import ExploreGyms from './Beta/ExploreGyms.js'

function App() {
  return (
    <div className="App">
      <HistoryRouter history={history}>
          <Header />
            <Routes>
              {/* Site routes */}
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password/:id" element={<ResetPassword />} />
              <Route path="/about" element={<About />} />
              <Route path="/howitworks" element={<HowItWorks />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/policies" element={<Policies />} />
              <Route path="/contact-us" element={<ContactUs />} />

              {/* Tryit routes */}
              <Route path="/try-it" element={<TryDashboard />} />
              <Route path="/try-it/feedback/:id" element={<TryFeedback />} />     

              {/* Project routes */}
              <Route path="/explore-gyms" element={<ExploreGyms />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/gym/:id" element={<GymCanvas />} />
              <Route path="/feedback/:id" element={<Feedback />} />
              <Route path="/admin/create-gym" element={<CreateGym />} />
              <Route path="/admin/edit-gym/:id" element={<EditGym />} />
              <Route path="/admin/list-wallsets/:id" element={<ListWallSetsGym />} />
              <Route path="/admin" element={<AdminGyms />} />
              <Route path="/" element={<Home />} />
              <Route path='*' element={<PageNotFound />} />
            </Routes>
          <Footer />
        </HistoryRouter>
    </div>
  );
}

export default App;
     