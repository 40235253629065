import { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { MoreHorizontal, CalendarDays } from "lucide-react"
import { Button } from "../components/ui/button.jsx"
import { Badge } from "../components/ui/badge"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../components/ui/breadcrumb.jsx"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/ui/card.jsx"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuCheckboxItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
  DropdownMenuSeparator
} from "../components/ui/dropdown-menu.jsx"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table.jsx"
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../components/ui/tabs.jsx"
import {
    Pagination,
    PaginationContent,
    PaginationItem,
    PaginationLink,
    PaginationNext,
    PaginationPrevious,
  } from "../components/ui/pagination.jsx"
  import {
    Popover,
    PopoverContent,
    PopoverTrigger,
  } from "../components/ui/popover.jsx"
  import { ScrollArea, ScrollBar } from "../components/ui/scroll-area.jsx"


import useQueryMockFeedback from '../api/Climb/useQueryMockFeedback.js'
import {WallFilter} from '../blocks/components/data/WallFilter.js'
import {GradeFilter} from '../blocks/components/data/GradeFilter.js'



const TryFeedback = () => {
    const { id } = useParams()
    const [ pageNum, setPageNum ] = useState(0)
    const [ itemsPerPage, setItemsPerPage ] = useState(10)
    const [ wallFilterList, setWallFilterList ] = useState(null)
    const [ gradeRanges, setGradeRanges ] = useState(null)
    const [ omitBroken, setOmitBroken ] = useState(true)
    const [ sortObj, setSortObj ] = useState({needsSorting: false, fieldName: ""})

    const { data, isLoading, error, refetch } = useQueryMockFeedback(id, pageNum, itemsPerPage, wallFilterList, gradeRanges, omitBroken, sortObj)

    const resetFilter = () => {
        setWallFilterList(null)
        setGradeRanges(null)
    }

    const createWallFilterList = () => {
        const formattedWallFilterList = data.walls.map((wall) => {
            const formattedWallSetFilterList = wall.wallSets.map((wallSet) => {
                return {
                    ...wallSet,
                    filter: true
                }
            })

            return {
                ...wall,
                filter: true,
                wallSets: formattedWallSetFilterList
            }
        })  

        setWallFilterList(formattedWallFilterList)
    }

    const createGradeRangeList = () => {
        const formattedGradeRangeList = data.gradingSystem.map((gradeInterval) => {
            return {
                grade: gradeInterval,
                filter: true
            }
        })

        setGradeRanges(formattedGradeRangeList)
    }

    const addPage = () => {
        if (pageNum < Math.ceil(data.feedbackLength / itemsPerPage) - 1) {
            setPageNum(pageNum + 1)
        }
    }

    const removePage = () => {
        if (pageNum > 0) {
            setPageNum(pageNum - 1)
        }
    }

    const handlePageChange = (value) => {
        setPageNum(value)
    }

    const chooseItemsPerPage = (value) => {
        setPageNum(0)
        setItemsPerPage(value)
    }

    const handleWallFilterChange = (_wallID, filterValue) => {
        const updatedWallFilterList = wallFilterList.map((wall) => {
            let updatedWall = {...wall}
            if (wall.wallID == _wallID) {
                updatedWall.filter = filterValue
            }

            return updatedWall
        })

        setWallFilterList(updatedWallFilterList)
    }

    const handleWallSetFilterChange = (_wallID, _wallSetID, filterValue) => {
        const updatedWallFilterList = wallFilterList.map((wall) => {
            let updatedWall = {...wall}

            if (wall.wallID == _wallID) {
                const updatedWallSetFilterList = wall.wallSets.map((wallSet) => {
                    let updatedWallSet = {...wallSet}
                    if (wallSet.wallSetID == _wallSetID) {
                        updatedWallSet.filter = filterValue                
                    }
                    return updatedWallSet
                })
    
                updatedWall.wallSets = updatedWallSetFilterList
    
            }

            return updatedWall
        })

        setWallFilterList(updatedWallFilterList)
    }

    const handleGradeRangeChange = (_grade, filterValue) => {
        const updatedGradeRangeList = gradeRanges.map((grade) => {
            let updatedGrade = {...grade}
            if (grade.grade == _grade) {
                updatedGrade.filter = filterValue
            }

            return updatedGrade
        })

        setGradeRanges(updatedGradeRangeList)
    }

    const handleSorting = (field) => {
        const sortingOrder = sortObj.needsSorting == false ? "ASC" : sortObj.needsSorting == "ASC" ? "DES" : sortObj.needsSorting == "DES" ? "ASC" : ""
        setSortObj({needsSorting: sortingOrder, fieldName: field})
    }

    const handleOmitBrokenChange = (value) => {
        setOmitBroken(value)
    }

    useEffect(() => {
        refetch()
    }, [pageNum, itemsPerPage, sortObj, omitBroken])

    useEffect(() => {
        if (data && wallFilterList == null && gradeRanges == null) {
            refetch()
        }
    }, [data, wallFilterList, gradeRanges])

    useEffect(() => {
        if (data !== undefined) {   
            if (wallFilterList == null) {
                createWallFilterList()
            } 
            
            if (gradeRanges == null) {
               createGradeRangeList()
            }            
        }
    }, [data])

    return (
        <div className="flex min-h-screen w-full flex-col bg-muted/40">
            <div className="flex flex-col sm:gap-4 sm:py-4 sm:pl-4">
                <header className="top-0 z-30 flex h-14 items-center gap-4 border-b bg-background px-4 sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6">
                <Breadcrumb className="md:flex">
                    <BreadcrumbList>
                    <BreadcrumbItem>
                        <BreadcrumbLink asChild>
                        <Link to={{pathname: "/try-it"}}>Dashboard</Link>
                        </BreadcrumbLink>
                    </BreadcrumbItem>                
                    <BreadcrumbSeparator />
                    <BreadcrumbItem>
                        <BreadcrumbPage>Feedback</BreadcrumbPage>
                    </BreadcrumbItem>
                    </BreadcrumbList>
                </Breadcrumb>                
                </header>
                <main className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
                <Tabs defaultValue="complete">
                    <div className="flex items-center">
                    <TabsList>
                        <TabsTrigger onClick={() => {handleOmitBrokenChange(true); setPageNum(0);}} value="complete">Complete</TabsTrigger>
                        <TabsTrigger onClick={() => {handleOmitBrokenChange(false); setPageNum(0);}} value="incomplete">Incomplete</TabsTrigger>
                    </TabsList>
                    <div className="ml-auto flex items-center gap-2">
                        <GradeFilter 
                            gradeRangeList={gradeRanges} 
                            handleGradeRangeChange={handleGradeRangeChange}
                            refetchOnConfirm={refetch}
                        />
                        <WallFilter 
                            wallFilterList={wallFilterList}
                            handleWallFilterChange={handleWallFilterChange}
                            handleWallSetFilterChange={handleWallSetFilterChange}
                            refetchOnConfirm={refetch}
                        />
                        <Button variant="destructive" onClick={() => resetFilter()}>Reset</Button>
                    </div>
                    </div>
                    <TabsContent value={omitBroken == true ? "complete" : "incomplete"}>
                        <Card x-chunk="dashboard-06-chunk-0">
                            <CardHeader >
                            <CardTitle>Feedback {data && data.gymName ? " - " + data.gymName : null}</CardTitle>
                            <CardDescription>
                            { data && data.feedbackLength ?
                                <>
                                Showing {" "}
                                <strong>
                                    {pageNum * itemsPerPage + 1} - {" "}
                                    {(pageNum + 1) * itemsPerPage < (data.feedbackLength) ? (pageNum + 1) * itemsPerPage : data.feedbackLength}
                                </strong> 
                                {" "} of {" "}
                                <strong>
                                    {data && data.feedbackLength}
                                </strong>
                                {" "} feedback
                                </>  
                            :
                                <span>Manage your gym feedback</span>                          
                            }
                            </CardDescription>
                            </CardHeader>
                            <CardContent>
                            <Table>
                                <TableHeader>
                                <TableRow>
                                    <TableHead className="hidden w-[100px] sm:table-cell">
                                        <span className="sr-only">#</span>
                                    </TableHead>
                                    <TableHead>
                                        <Button onClick={() => handleSorting("level")} variant="secondary">Rating</Button>
                                    </TableHead>
                                    <TableHead>
                                        <Button onClick={() => handleSorting("notes")} variant="secondary">Feedback</Button>
                                    </TableHead>
                                    <TableHead className="hidden md:table-cell">
                                        <Button onClick={() => handleSorting("wallName")} variant="secondary">Wall</Button>
                                    </TableHead>
                                    <TableHead className="hidden md:table-cell">
                                        <Button onClick={() => handleSorting("climbGrade")} variant="secondary">Grade</Button>
                                    </TableHead>
                                    <TableHead className="hidden md:table-cell">
                                        <Button onClick={() => handleSorting("climbName")} variant="secondary">Climb</Button>
                                    </TableHead>
                                    <TableHead className="hidden md:table-cell">
                                        <Button onClick={() => handleSorting("wallSet")} variant="secondary">Wall Set</Button>
                                    </TableHead>
                                    <TableHead>
                                        <span className="sr-only">Actions</span>
                                    </TableHead>
                                </TableRow>
                                </TableHeader>
                                <TableBody>
                                    { data && data.feedback.map((item, idx) => {
                                        return <TableRow>
                                            <TableCell className="hidden sm:table-cell">
                                                {idx + 1}
                                            </TableCell>
                                            <TableCell>
                                                <p style={{ backgroundColor: `${item.color}`}} className={`w-fit font-medium text-indigo-50 py-2 px-4 rounded text-nowrap`}>{item.level}</p>
                                            </TableCell>
                                            <TableCell className="text-nowrap">
                                            <Popover>
                                                <PopoverTrigger>{item.notes}</PopoverTrigger>
                                                <PopoverContent className="w-64">
                                                    <div className="flex flex-col">
                                                        <div className="flex flex-row justify-start mt-2">
                                                            <h4 className="text-sm font-semibold w-12">
                                                                Rating
                                                            </h4>
                                                            <p style={{ backgroundColor: `${item.color}`}} className={`w-fit font-medium text-indigo-50 py-1 px-2 ml-2 rounded text-nowrap`}>{item.level}</p>
                                                        </div>
                                                        <div className="flex flex-row justify-start mt-2">
                                                            <h4 className="text-sm font-semibold w-12">
                                                                Notes
                                                            </h4>
                                                            <p className="text-sm text-wrap ml-2 border p-1">
                                                                {item.notes}
                                                            </p>
                                                        </div>
                                                        <div className="flex flex-row justify-start mt-2">
                                                            <h4 className="text-sm font-semibold w-12">
                                                                 Wall
                                                            </h4>
                                                            <p className="text-sm text-wrap ml-2 py-1">
                                                                {item.wallName}
                                                            </p>
                                                        </div>
                                                        <div className="flex flex-row justify-start mt-2">
                                                            <h4 className="text-sm font-semibold w-12">
                                                                Grade
                                                            </h4>
                                                            <p className="text-sm text-wrap ml-2 py-1">
                                                                {item.climbGrade}
                                                            </p>
                                                        </div>
                                                        <div className="flex flex-row justify-start mt-2">
                                                            <h4 className="text-sm font-semibold w-12">
                                                                Climb
                                                            </h4>
                                                            <p className="text-sm text-wrap ml-2  py-1">
                                                                {item.climbName}
                                                            </p>
                                                        </div>
                                                        <div className="flex items-center pt-2">
                                                        <CalendarDays className="mr-2 h-4 w-4 opacity-70" />{" "}
                                                        <span className="text-xs text-muted-foreground">
                                                            Wallset: {new Date(Date.parse(item.wallSet)).toLocaleDateString()}
                                                        </span>
                                                        </div>
                                                    </div>
                                                </PopoverContent>
                                            </Popover>                                                
                                            </TableCell>
                                            <TableCell className="hidden text-nowrap md:table-cell">
                                                {item.wallName}
                                            </TableCell>
                                            <TableCell className="hidden md:table-cell">
                                                { item.climbGrade == "[CLIMB DELETED]" ?
                                                    <Badge variant="secondary">Data Deleted</Badge>
                                                :
                                                    item.climbGrade
                                                }
                                            </TableCell>
                                            <TableCell className="hidden md:table-cell">
                                                { item.climbName == "[CLIMB DELETED]" ?
                                                <Badge variant="secondary">Data Deleted</Badge>
                                                :
                                                    item.climbName
                                                }
                                            </TableCell>
                                            <TableCell className="hidden md:table-cell">
                                                {item.wallSet}
                                            </TableCell>
                                            <TableCell>
                                                <DropdownMenu>
                                                    <DropdownMenuTrigger asChild>
                                                    <Button
                                                        aria-haspopup="true"
                                                        size="icon"
                                                        variant="ghost"
                                                    >
                                                        <MoreHorizontal className="h-4 w-4" />
                                                        <span className="sr-only">Toggle menu</span>
                                                    </Button>
                                                    </DropdownMenuTrigger>
                                                    <DropdownMenuContent align="end">
                                                    <DropdownMenuLabel>Actions</DropdownMenuLabel>
                                                    <DropdownMenuItem>Edit</DropdownMenuItem>
                                                    <DropdownMenuItem>Delete</DropdownMenuItem>
                                                    </DropdownMenuContent>
                                                </DropdownMenu>
                                            </TableCell>
                                        </TableRow>
                                    })}                        
                                </TableBody>
                            </Table>
                            </CardContent>
                            <CardFooter>
                                { data && data.feedbackLength ?
                                        <>                                            
                                            <Pagination>
                                                <PaginationContent>
                                                    <PaginationItem>
                                                        <PaginationPrevious onClick={() => removePage()} href="#" />
                                                    </PaginationItem>
                                                    <ScrollArea className="w-24 whitespace-nowrap rounded-md border">
                                                        <div className="flex w-max space-x-4 p-2">
                                                            {
                                                                [...Array(Math.ceil(data.feedbackLength / itemsPerPage))].map((e, idx) => {
                                                                    if (idx < Math.ceil(data.feedbackLength / itemsPerPage)) {
                                                                        return <PaginationItem>
                                                                                <PaginationLink onClick={() => handlePageChange(idx)} isActive={pageNum == idx ? true : false} href="#">{idx + 1}</PaginationLink>
                                                                            </PaginationItem>
                                                                    }
                                                                    
                                                                })
                                                            }
                                                        </div>
                                                        <ScrollBar orientation="horizontal" />
                                                    </ScrollArea>                                                    
                                                    <PaginationItem>
                                                        <PaginationNext onClick={() => addPage()} href="#" />
                                                    </PaginationItem>
                                                </PaginationContent>
                                            </Pagination> 
                                        </>                                                                            
                                    :
                                        null
                                } 
                                <div className="text-xs text-muted-foreground"> 
                                    <DropdownMenu>
                                        <DropdownMenuTrigger asChild>
                                        <Button
                                            aria-haspopup="true"
                                            size="icon"
                                            variant="ghost"
                                        >
                                            <MoreHorizontal className="h-4 w-4" />
                                        </Button>
                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent align="end">
                                            <DropdownMenuLabel>Items per page:</DropdownMenuLabel>
                                            <DropdownMenuSeparator />
                                            <DropdownMenuCheckboxItem 
                                                onClick={(e) => {e.preventDefault(); chooseItemsPerPage(10)}} 
                                                checked={itemsPerPage == 10}>
                                                10
                                            </DropdownMenuCheckboxItem>
                                            <DropdownMenuCheckboxItem 
                                                onClick={(e) => {e.preventDefault(); chooseItemsPerPage(20)}} 
                                                checked={itemsPerPage == 20}>
                                                20
                                            </DropdownMenuCheckboxItem>
                                            <DropdownMenuCheckboxItem 
                                                onClick={(e) => {e.preventDefault(); chooseItemsPerPage(50)}} 
                                                checked={itemsPerPage == 50}>
                                                50
                                            </DropdownMenuCheckboxItem>
                                            <DropdownMenuCheckboxItem 
                                                onClick={(e) => {e.preventDefault(); chooseItemsPerPage(100)}} 
                                                checked={itemsPerPage == 100}>
                                                100
                                            </DropdownMenuCheckboxItem>
                                        </DropdownMenuContent>
                                    </DropdownMenu>                                                                  
                                </div>                                                                                                                     
                            </CardFooter>
                        </Card>
                    </TabsContent>
                </Tabs>
                </main>
            </div>
        </div>
    )
}

export default TryFeedback