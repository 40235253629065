import { Link } from 'react-router-dom';
import { useState } from 'react'
import { tailspin, bouncy } from 'ldrs'
import { ButtonLink } from "../blocks/components/buttons/ButtonLink"
import {
    Card,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
  } from "../components/ui/card"

tailspin.register()
bouncy.register()

const Loading = () => {
    const [ userId, setUserId ] = useState(sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : null)

    return (
        <div className="flex min-h-screen w-full flex-col bg-muted/40">
            <Card className="w-72 mx-auto my-12">
                <CardHeader>
                    <div className="mb-4">
                        <l-tailspin
                        size="200"
                        stroke="12"
                        stroke-length="0.15"
                        bg-opacity="0.1"
                        speed="1.5" 
                        color="#54a8c7" 
                        ></l-tailspin>             
                   </div>
                    <CardTitle className="text-2xl text-center">Loading</CardTitle>
                    <CardDescription className="text-center">
                        We are loading the page for you. 
                    </CardDescription>
                </CardHeader>
                <CardFooter className="flex flex-col justify-center">
                    <p className="text-sm text-center mb-4">Trouble loading? Click the link below to redirect back home.</p>
                    { userId !== null ?
                        <ButtonLink link={"/dashboard"} buttonName={"Home"} />
                    :
                        <ButtonLink link={"/"} buttonName={"Home"} />
                    } 
                </CardFooter>
            </Card>
        </div>      
    )
}

export default Loading