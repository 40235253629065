import { Link } from 'react-router-dom'
import { ButtonLink } from '../buttons/ButtonLink'
import { Button } from '../../../components/ui/button'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
  } from "../../../components/ui/table"
  import {
    Card,
    CardDescription,
    CardContent,
    CardFooter,
    CardHeader,
    CardTitle,
  } from "../../../components/ui/card"
  import {
    Popover,
    PopoverContent,
    PopoverTrigger,
  } from "../../../components/ui/popover.jsx"

  export function ExploreCards({exploreGyms, today}) {
    // speeding ticket 
    return (<>
        { exploreGyms.map((gym, idx) => {
            return <Card className="w-60 min-h-80 mb-4 mx-4">
                    <CardHeader>
                        <CardTitle className="h-14 text-wrap overflow-scroll">{gym.gymName}</CardTitle>
                        <CardDescription className="text-nowrap overflow-auto">
                            {"[" + today.day + "] " + gym.openHours[today.hoursIdx]}   
                        </CardDescription>
                    </CardHeader>
                    <CardContent>
                        <div className="flex flex-row justify-start items-center mt-2">
                            <h4 className="text-sm font-semibold w-14 mr-2">
                            Address
                            </h4>
                            <p className="w-32 text-sm text-nowrap overflow-auto ml-2 py-1">
                            {gym.location.address}
                            </p>
                        </div>
                        <div className="flex flex-row justify-start items-center mt-2">
                            <h4 className="text-sm font-semibold w-14 mr-2">
                            City
                            </h4>
                            <p className="w-32 text-sm text-nowrap overflow-auto ml-2 py-1">
                            {gym.location.city}
                            </p>
                        </div>
                        <div className="flex flex-row justify-start items-center mt-2">
                            <h4 className="text-sm font-semibold w-14 mr-2">
                            Province
                            </h4>
                            <p className="w-32 text-sm text-nowrap overflow-auto ml-2 py-1">
                            {gym.location.province}
                            </p>
                        </div>
                        <div className="flex flex-row justify-center mt-4">
                                  <ButtonLink link={"/gym/" + gym._id} buttonName="Go to Gym!" />
                              </div>     
                    </CardContent>
                </Card>
        })}
    </>)
  }
  