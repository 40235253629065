import LightBulb from "../assets/img/lightbulb.png"
import Chart from "../assets/img/chart.png"
import Dev from "../assets/img/dev.png"
import { Link } from 'react-router-dom'
import { Button } from '../components/ui/button'
import { Separator } from '../components/ui/separator'
import { H1 } from '../blocks/components/typography/H1.js'
import { H3 } from '../blocks/components/typography/H3.js'
import { BlockQuote } from '../blocks/components/typography/BlockQuote.js'
import { Paragraph } from '../blocks/components/typography/Paragraph.js'
import { ButtonLink } from '../blocks/components/buttons/ButtonLink.js'
import { CardInfo } from '../blocks/components/cards/CardInfo.js'
import { CardInfoIcon } from '../blocks/components/cards/CardInfoIcon.js'

const Home = () => {
    return (
        <>
            {/* <section className="site-panel header-spacer"> */}
            <section className="container max-w-full bg-slate-50">
                <div className="w-fit text-center mx-auto my-auto pt-14 pb-4">
                    <H1 text={"Welcome to beta-break!"}
                        color={"text-indigo-900"} />
                    <Paragraph text={"Showcase your climbing gym, attract new climbers, and keep your existing ones engaged."} 
                               color={"text-indigo-700"} />  
                    <Separator className="my-4" />
                    <div className="flex flex-row justify-center my-4">
                        <iframe className="rounded-lg w-80 h-44 sm:h-sm-yt sm:w-sm-yt lg:h-lg-yt lg:w-lg-yt" src="https://www.youtube.com/embed/qJOLvqdVD-I?si=TkWQT0nRibF3y-lD" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                    <div className="flex flex-row justify-center">
                    <Link to={{pathname: "/explore-gyms"}} className="mx-2">
                        <Button 
                            size="sm" >
                            Explore Gyms!
                        </Button>
                    </Link> 
                    <Link to={{pathname: "/try-it"}} className="mx-2">
                        <Button 
                            size="sm"
                            variant="destructive" >
                            Try it!
                        </Button>
                    </Link>  
                    </div>
                    
                </div>
            </section>            
            <section className="container max-w-full px-2 py-4 bg-indigo-950">
                    <H3 text={"What can we provide?"}
                        color={"text-indigo-200"} />
                    <div className="flex flex-col justify-center items-center sm:flex-row sm:justify-start sm:overflow-x-scroll lg:justify-around">
                        <CardInfo heading={"Showcase Your Facility"}
                                        subtext={"Strengthen your gym’s online presence with high-quality, interactive visuals."}
                                        linkButton={<ButtonLink link={"/about"} buttonName={"Learn more!"} />} />
                        <CardInfo heading={"Attract and Engage"}
                                        subtext={"Draw in potential members by offering a virtual tour of your climbing walls and holds. "}
                                        linkButton={<ButtonLink link={"/howitworks"} buttonName={"Process"} />} />
                        <CardInfo heading={"Enhanced Member Experience"}
                                        subtext={"Current members gain a head start as they preview newly set walls before they step in your gym"}
                                        linkButton={<ButtonLink link={"/blog"} buttonName={"Check it out!"} />} /> 
                        <CardInfo heading={"Review Route Feedback"}
                                        subtext={"Review feedback provided by members reflecting on a route's difficulty"}
                                        linkButton={<ButtonLink link={"/blog"} buttonName={"Check it out!"} />} />                                                                                           
                    </div>
            </section>
            <section>
                <div className="py-5 mx-2">
                    <BlockQuote text={"- SUGGESTIONS ON FEATURES?"} color={"text-indigo-500"} />
                    <H3 text={"We would love to connect and hear about features you would like implemented."}
                        color={"text-indigo-950"} />
                    <div className="flex-col pt-2">
                        <CardInfoIcon heading={"Feature Implementation"}
                                      subtext={"You know what you want, and we would love to add features allowing you to use beta-break in your best way."}
                                      icon={<img src={LightBulb} className="w-12 h-auto pt-2" />} />     
                        {/* <CardInfoIcon heading={"Community Updates"}
                                      subtext={"Check out our blog to see what we are working on, guides on using beta-break, and more!"}
                                      icon={<img src={Chart} className="w-12 h-auto pt-2" />} />      */}
                        <CardInfoIcon heading={"Development Updates"}
                                      subtext={"We run regular maintenance and updates. If you are running into issues, please contact us!"}
                                      icon={<img src={Dev} className="w-12 h-auto pt-2" />} />                                                                                                                                                                              
                        <div className="flex flex-row justify-center mt-4">
                            <ButtonLink link={"/contact-us"} buttonName={"Contact Us"} />
                        </div>
                    </div>
                </div>
            </section>
        </>    
    )
}

export default Home