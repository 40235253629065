import { arraySort } from './sortClimbs.js';

export const gymDataFormatter = (data, currentWallID, currentWallSetID, 
                                handleUpdatedWall, setClimbs, setWallHeader
                                ) => {

    const wallIndex = data.walls.findIndex((wall) => wall._id == currentWallID)
    setWallHeader(data.walls[wallIndex].wallName)

    let currentWallSet
    const wallSets = data.walls[wallIndex].wallSets
    for (let i = 0; i < wallSets.length; i++) {
        if (wallSets[i].id == currentWallSetID) {
            currentWallSet = wallSets[i]
        }
    }

    const renderedClimbs = currentWallSet.climbs.map((climb, idx) => {
        let vectorizedPoints = []
        if (climb.borderPoints.length > 0) {
            vectorizedPoints = climb.borderPoints.map((point) => {
                if (point[0]['$numberDecimal'] !== undefined) {
                    return [parseFloat(point[0]['$numberDecimal']), parseFloat(point[1]['$numberDecimal']), parseFloat(point[2]['$numberDecimal'])]
                }
                return [parseFloat(point[0]), parseFloat(point[1]), parseFloat(point[2])]
            })
        }
        
        return {
            id: climb.id,
            authorID: climb.authorID,
            authorName: climb.authorName,
            name: climb.name,
            grade: climb.grade,
            color: climb.color,
            likes: climb.likes,
            tags: climb.tags,
            borderPoints: vectorizedPoints
        }
    })

    arraySort(renderedClimbs, "grade", "ASC")

    handleUpdatedWall()
    setClimbs(renderedClimbs)

    return
}