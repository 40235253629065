import { create } from 'zustand'
import _ from 'lodash'
import { createPointsOffBackendData } from '../../helpers/parseProject.js'
import { useBorderPointsStore } from '../data/useBorderPointsStore.js'
import { arraySort } from '../../helpers/sortClimbs.js'

export const useGymStore = create((set) => ({
    currentWallID: null,
    setCurrentWallID: (data) => set((state) => ({currentWallID: data})),
    currentWallSetID: null,
    setCurrentWallSetID: (data) => set((state) => ({currentWallSetID: data})),
    climbs: [],
    setClimbs: (data) => set((state) => ({climbs: data})),
    climbBorders: [],
    setClimbBorders: (data) => set((state) => ({climbBorders: data})),
    highlightClimbID: null,
    setHighlightClimbID: (data) => set((state) => ({highlightClimbID: data})),
    potentialHighlightClimb: [],
    setPotentialHighlightClimb: (data) => set((state) => ({potentialHighlightClimb: data})),
    climbToEdit: {},
    setClimbToEdit: (data) => set((state) => ({climbToEdit: data})),
}))

const getClimbsCopy = () => {
    return _.cloneDeep(useGymStore.getState().climbs)
}

export const getWallSetOptions = (gymData) => {
    const currentWallID = useGymStore.getState().currentWallID
    const wallIndex = gymData.walls.findIndex((wall) => wall._id == currentWallID)

    if (currentWallID !== null && wallIndex !== -1) {
        const wallSetOptions = gymData.walls[wallIndex].wallSets.map((wallSet) => {
            return { name: wallSet.date, wallSetID: wallSet.id, wallModel: {modelURL: wallSet.wallSetURL, geometry: wallSet.wallGeometry, material: wallSet.wallMaterial}}
        })
    
        return wallSetOptions
    }

    return [{name: '', wallSetID: '', wallModel: {modelURL: '', geometry: '', material: ''}}]
}

export const hoverClimb = (bool, climbId, Points, Point, PointMaterial) => {
    const highlightClimbID = useGymStore.getState().highlightClimbID

    if (bool) {
        updateHoverClimb(climbId, Points, Point, PointMaterial)
    } else if (!bool && !highlightClimbID) {
        handleClearHighlightedClimb()
    }
}

// updates a hold to hover given a uhid (unique hold id)
export const updateHoverClimb = (climbId, Points, Point, PointMaterial) => {
    const climbs = getClimbsCopy()
    const setPotentialHighlightClimb = useGymStore.getState().setPotentialHighlightClimb
    
    let oldClimb = {}
    climbs.map((climb) => {

        if (climb.id == climbId) {
            oldClimb = {...climb}
        }
    })

    setPotentialHighlightClimb(createPointsOffBackendData(Points, Point, PointMaterial, oldClimb.id, oldClimb.borderPoints, oldClimb.color))    
}

// clears and cleans up potential old highlighted hold
export const handleClearHighlightedClimb = () => {
    useGymStore.getState().setPotentialHighlightClimb([])
}

export const prepareClimbToEdit = (climbIdx) => {
    const setCurrentPoints = useBorderPointsStore.getState().setCurrentPoints
    const UseGymStore = useGymStore.getState()

    const updatedClimbBorders = UseGymStore.climbBorders.filter((border, idx) => {
        if (idx !== climbIdx) {
            return border
        }
    })

    handleClearHighlightedClimb()

    UseGymStore.setClimbBorders([])

    UseGymStore.setClimbToEdit(UseGymStore.climbs[climbIdx])

    setCurrentPoints(UseGymStore.climbs[climbIdx].borderPoints)
}

export const exitEditingBorder = () => {
    const setCurrentPoints = useBorderPointsStore.getState().setCurrentPoints

    // clear holds and reset modals to clean
    setCurrentPoints([])
    useGymStore.getState().setClimbToEdit({})
}

export const toggleBorderVisibility = (Points, Point, PointMaterial, climbID) => {
    const climbBorders = useGymStore.getState().climbBorders
    const climbs = useGymStore.getState().climbs
    const setClimbBorders = useGymStore.getState().setClimbBorders

    const borderIdx = climbBorders.findIndex((border) => border.props.climbID == climbID)
    let updatedBorders
    if (borderIdx == -1) {   
     
        const climbIdx = climbs.findIndex((climb) => climb.id == climbID)

        if (climbs[climbIdx].borderPoints) {            
            const singleClimbsBorder = createPointsOffBackendData(Points, Point, PointMaterial, climbs[climbIdx].id, climbs[climbIdx].borderPoints, climbs[climbIdx].color)
            
            updatedBorders = [...climbBorders, singleClimbsBorder]
        }
    } else {
        updatedBorders = climbBorders.filter((border) => border.props.climbID !== climbID)
    }
    
    setClimbBorders(updatedBorders)
}

export const highlightClimbsListBorders = (Points, Point, PointMaterial, gradeFilter) => {
    const climbs = useGymStore.getState().climbs
    const setClimbBorders = useGymStore.getState().setClimbBorders

    let updatedBorders = []
    climbs.forEach((climb) => {
        let showClimb = false

        for (const element of gradeFilter) {
            if (element.grade == climb.grade && element.filter == true) {
                showClimb = true
            }
        }

        if (showClimb == true) {
            const singleClimbsBorder = createPointsOffBackendData(Points, Point, PointMaterial, climb.id, climb.borderPoints, climb.color)

            updatedBorders.push(singleClimbsBorder)
        }
    })

    setClimbBorders(updatedBorders)
}

export const sortClimbs = (sortOrder, setSortOrder) => {
    const climbs = useGymStore.getState().climbs
    const sortingOrder = sortOrder == "ASC" ? "DES" : "ASC"

    arraySort(climbs, "grade", sortingOrder)

    setSortOrder(sortingOrder)
}