import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Loading from '../Site/Loading.js'
import PageIsPrivate from './PageIsPrivate.js'
import PageNotFound from '../Site/PageNotFound.js'
import UseMutateUpdateGym from '../api/Gym/UseMutateUpdateGym'
import useQueryGymAndUsers from '../api/Gym/useQueryGymAndUsers.js'
import FormGym from './FormGym'
import '../tailwind.css'

const baseFormState = { isLoaded: false,
    location: { address: '', city: '', province: '', postalCode: ''},
    openHours: ['', '', '', '', '', '', ''],
    gradingType: "text",
    gradingSystem: [],
    ratingSystem: [],
    walls: [],
    collaborators: []}

const EditGym = () => {
    const { id } = useParams()
    const [ userId, setUserId ] = useState(sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : '')

    const { data, isLoading, error } = useQueryGymAndUsers(id)

    const [ form, setForm ] = useState(baseFormState)
    const [ alertWarning, setAlertWarning] = useState(false)
    const [ alertMessages, setAlertMessages ] = useState([])

    useEffect(() => {
        if (data !== undefined) {
          setForm({...data.gym})

        }        
    }, [data])
    
    if (isLoading) return <Loading />

    if (error) {
        if (error.response.data.error == "private") {
            return <PageIsPrivate errMessage={error.response.data.message} />
        } else {
            return <PageNotFound />
        }
    } 

    return (
        <FormGym 
            mode={"Edit"} 
            heading={"Edit a Gym"} 
            subHeading={"Modify or add gym information"} 
            form={form} 
            setForm={setForm} 
            userList={data.userList}
            alertWarning={alertWarning}
            setAlertWarning={setAlertWarning}
            alertMessages={alertMessages}
            setAlertMessages={setAlertMessages}
            submitFunction={<UseMutateUpdateGym gymInfo={form} setNotification={setAlertWarning} setMessage={setAlertMessages} />}
        />

    )
}


export default EditGym