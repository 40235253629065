import { useState, useEffect } from 'react'
import UseMutateAdminCreateWall from '../../../api/Gym/UseMutateAdminCreateWall.js'  
  import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
    CardFooter
  } from "../../../components/ui/card.jsx"
import { Input } from '../../../components/ui/input.jsx'
import { Label } from '../../../components/ui/label.jsx'
import { CircleX } from "lucide-react"

export function AddWall({modalVis, setModalVis, gymId}) {   
    const [ newWall, setNewWall ] = useState("") 

    useEffect(() => {
        setNewWall({
            wallName: "", 
            wallSet: {
                date: Date(),
                climbs: [],
                wallSetURL: '',
                wallGeometry: '',
                wallMaterial: '',
            }
        })
    }, [])

    if (modalVis) {
        return (
            <div className="fixed top-0 h-full w-full z-20 bg-black/10">
                <Card className="mt-12 mx-auto w-[18rem] h-fit box-border rounded-lg p-1 overflow-auto">
                    <CardHeader >
                        <div className="flex flex-row justify-between pb-2">
                        <CardTitle className="w-fit">Add Wall</CardTitle>     
                        <CircleX 
                            color="red"
                            className="h-4 w-4"
                            onClick={() => setModalVis(false)}
                        />  
                        </div>                                                                                                          
                    </CardHeader>                    
                    <CardContent className="p-4 pt-0">
                        <Label
                            className="w-24"
                            htmlFor="wallSet">Wall Name {" "}</Label>
                        <Input
                        id="wallName" 
                        name="wallName"
                        required
                        type="text"
                        className="w-full"
                        placeholder="Enter a wall name"
                        value={newWall.wallName}
                        onChange={(e) => setNewWall({...newWall, wallName: e.target.value})} />     

                        <Label
                            className="w-24"
                            htmlFor="wallSet">Wall Set {" "}</Label>
                        <Input
                        id="date" 
                        name="date"
                        required
                        disabled
                        type="text"
                        className="w-full"
                        placeholder="Enter a date"
                        value={newWall.wallSet.date}
                        />
                        <Label
                            className="w-24"
                            htmlFor="wallSetURL">Wall Set URL {" "}</Label>
                        <Input
                        id="wallSetURL" 
                        name="wallSetURL"
                        required
                        type="text"
                        className="w-full"
                        placeholder="Enter a wall set URL"
                        value={newWall.wallSet.wallSetURL}
                        onChange={(e) => setNewWall({...newWall, wallSet: {...newWall.wallSet, wallSetURL: e.target.value}})}
                        />
                        <Label
                            className="w-24"
                            htmlFor="wallGeometry">Wall Set Geometry {" "}</Label>
                        <Input
                        id="wallGeometry" 
                        name="wallGeometry"
                        required
                        type="text"
                        className="w-full"
                        placeholder="Enter the name of the geometry"
                        value={newWall.wallSet.wallGeometry}
                        onChange={(e) => setNewWall({...newWall, wallSet: {...newWall.wallSet, wallGeometry: e.target.value}})}
                        />
                        <Label
                            className="w-24"
                            htmlFor="wallGeometry">Wall Set Material {" "}</Label>
                        <Input
                        id="wallMaterial" 
                        name="wallMaterial"
                        required
                        type="text"
                        className="w-full"
                        placeholder="Enter the name of the material"
                        value={newWall.wallSet.wallMaterial}
                        onChange={(e) => setNewWall({...newWall, wallSet: {...newWall.wallSet, wallMaterial: e.target.value}})}
                        />                   
                    </CardContent>                      
                    <CardFooter className="flex flex-row justify-center">
                        <UseMutateAdminCreateWall 
                            gymId={gymId}
                            newWall={newWall} 
                            setModalVis={setModalVis} />               
                    </CardFooter> 
                </Card>
            </div>            
        )
    } else return
    
}