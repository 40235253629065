import { ButtonLink } from '../blocks/components/buttons/ButtonLink'

const About = () => {
    return (
        <div className="container max-w-full mt-8">
          <h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl">
              About
          </h1>
          <p className="leading-7 [&:not(:first-child)]:mt-6">
              Hi, my name is Jonathan and I am an avid indoor boulderer! Following a hamstring tweak keeping me away from the 
              climbing wall for a week, debating max hang half crimps on my bathroom door frame for 7 seconds per set so as not to 
              lose my finger strength, I stumbled upon the idea of beta-break.
          </p>
          <h2 className="mt-10 scroll-m-20 border-b pb-2 text-3xl font-semibold tracking-tight transition-colors first:mt-0">
            Who is this for?
          </h2>          
          <blockquote className="mt-6 border-l-2 pl-6 italic">
          Elevate your climbing gym's appeal with our climbing gym wall route viewer. Our product offers a dynamic digital presence, allowing climbers to virtually explore your gym’s walls and holds from anywhere. This immersive experience not only showcases your facility in a compelling way but also makes it easier for current members to plan their climbs in advance.
          </blockquote>    
          <blockquote className="mt-6 border-l-2 pl-6 italic">
          A strong digital presence enhances your gym’s visibility, attracts new members, and keeps existing ones engaged by providing them with detailed insights into your climbing environment. By offering a seamless online experience, you’ll stand out in a competitive market by showcasing your unique gym.
          </blockquote>   
          <blockquote className="mt-6 border-l-2 pl-6 italic">
          Transform how climbers discover, plan, and engage with your gym. Start with a click and make every visit an exciting adventure
          </blockquote>   
          <div className="my-6 w-full overflow-y-auto">            
            <div className="flex flex-row justify-around mt-4">                
                <ButtonLink
                    link={"/"}
                    buttonName={"Home"}
                />
            </div>                    
          </div>        
        </div>
      )         
}

export default About
  