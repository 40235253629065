import { ButtonLink } from '../buttons/ButtonLink'
import { ScrollArea } from "../../../components/ui/scroll-area"
import { Separator } from "../../../components/ui/separator"

export function GymFollowing({following, dOTW}) {
    return (
        <ScrollArea className="">
            { following.map((gym, idx) => {
                return <div className={gym.id + "-" + idx}>
                    <div className="flex items-center gap-4">
                        <div className="grid gap-1">
                            <p className="text-sm font-medium leading-none">
                                {gym.gymName}
                            </p>
                            <p className="text-sm text-muted-foreground">
                                {dOTW + " - " + gym.hours}
                            </p>
                        </div>
                        <div className="ml-auto font-medium">
                            <ButtonLink link={"/gym/" + gym.id} buttonName={"Visit Gym"} />
                        </div>
                    </div>
                    <Separator className="my-2" />
                </div>
            })}
        </ScrollArea>             
    )
}