import { useRef, useEffect } from 'react'
import * as THREE from 'three'
import { CameraControls, Points, Point, PointMaterial} from '@react-three/drei'
import { useWallStore } from './data/useWallStore.js'
import { useGymStore } from './data/useGymStore.js'
import { useCoordinatesStore } from './data/useCoordinatesStore.js'
import { useBorderPointsStore, updateBorderPoints } from './data/useBorderPointsStore.js'
import { useCoordinateUtilities } from '../helpers/sceneUtilities.js'
import { createPointsOffBackendData } from '../helpers/parseProject.js'


const GymScene = ({highlightMode}) => {
    const cameraControlsRef = useRef()
    const rightLights = new THREE.Object3D().translateX(-10).translateZ(-10)
    rightLights.updateMatrixWorld()

    const leftLights = new THREE.Object3D().translateX(10).translateZ(-10)
    leftLights.updateMatrixWorld()

    const currentProject = useWallStore((state) => state.currentProject)
    const currentPoints = useBorderPointsStore((state) => state.currentPoints)
    const mouseCoords = useCoordinatesStore((state) => state.mouseCoords)
    const climbToEdit = useGymStore((state) => state.climbToEdit)
    const climbBorders = useGymStore((state) => state.climbBorders)
    const potentialHighlightClimb = useGymStore((state) => state.potentialHighlightClimb)

    const { setMouseDownCoord, setMouseUpCoord } = useCoordinateUtilities()

    useEffect(() => {
      // creates a new border point or updates a border point with the new mouseCoords position
      if (highlightMode) {
        updateBorderPoints(mouseCoords, climbToEdit.color, true)
      }
    }, [mouseCoords])

    return (
      <>
        <group position-y={-0.5} onPointerDown={(e) => {e.stopPropagation(); setMouseDownCoord(e.point);}} onPointerUp={(e) => {e.stopPropagation(); setMouseUpCoord(e.point);}}>
            <directionalLight color={'white'} position={[0, 15, 10]} />
            <directionalLight color={'white'} position={[0, 0, 3]} target={rightLights} />
            <directionalLight color={'white'} position={[0, 0, 3]} target={leftLights} />
            <CameraControls ref={cameraControlsRef} maxDistance={1000} />
            {createPointsOffBackendData(Points, Point, PointMaterial, climbToEdit.id, currentPoints, climbToEdit.color)}                          
            {potentialHighlightClimb}
            {climbBorders}
            {currentProject ? currentProject : null}
        </group>
      </>
    )
  }

export default GymScene