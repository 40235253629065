import UseMutateAdminEditWallName from '../../../api/Gym/UseMutateAdminEditWallName.js'  
  import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
    CardFooter
  } from "../../../components/ui/card.jsx"
import { Input } from '../../../components/ui/input.jsx'
import { Label } from '../../../components/ui/label.jsx'
import { CircleX } from "lucide-react"

export function EditWall({modalVis, setModalVis, gymId, wallId, editWallName, setEditWallName}) {   
    if (modalVis) {
        return (
            <div className="fixed top-0 h-full w-full z-20 bg-black/10">
                <Card className="mt-12 mx-auto w-[18rem] h-fit box-border rounded-lg p-1 overflow-auto">
                    <CardHeader >
                        <div className="flex flex-row justify-between pb-2">
                        <CardTitle className="w-fit">Add Wall</CardTitle>     
                        <CircleX 
                            color="red"
                            className="h-4 w-4"
                            onClick={() => setModalVis(false)}
                        />  
                        </div>                                                                                                          
                    </CardHeader>                    
                    <CardContent className="p-4 pt-0">
                        <Label
                            className="w-24"
                            htmlFor="wallSet">Wall Name {" "}</Label>
                        <Input
                        id="wallName" 
                        name="wallName"
                        required
                        type="text"
                        className="w-full"
                        placeholder="Enter a wall name"
                        value={editWallName}
                        onChange={(e) => setEditWallName(e.target.value)} />                                       
                    </CardContent>                      
                    <CardFooter className="flex flex-row justify-center">
                        <UseMutateAdminEditWallName
                            gymId={gymId}
                            wallId={wallId}
                            editWallName={editWallName} 
                            setModalVis={setModalVis} />               
                    </CardFooter> 
                </Card>
            </div>            
        )
    } else return
    
}