import { create } from 'zustand'
import { useBorderPointsStore } from '../data/useBorderPointsStore'
import { useGymStore } from '../data/useGymStore'
import { WallModel } from '../../assets/WallModel.jsx'

export const useWallStore = create((set) => ({
    currentWallModel: null,
    setCurrentWallModel: (data) => set((state) => ({currentWallModel: data})),
    wallIndex: 0,
    updateWallIndex: (data) => set((state) => ({wallIndex: data})),
    currentProject: {},
    setCurrentProject: (data) => set((state) => ({currentProject: data})),
    renderWall: (wallModel) => {                   
        return <WallModel modelURL={wallModel.modelURL} 
                            modelGeometry={wallModel.geometry} 
                            modelMaterial={wallModel.material} />
    }
}))

export const handleUpdatedWall = () => {
    const setCurrentProject = useWallStore.getState().setCurrentProject
    const currentWallModel = useWallStore.getState().currentWallModel
    const setClimbBorders = useGymStore.getState().setClimbBorders
    const renderWall = useWallStore.getState().renderWall

    setClimbBorders([])
    return setCurrentProject(renderWall(currentWallModel))
}

export const handleWallChange = (wallURL) => {
    const setCurrentPoints = useBorderPointsStore.getState().setCurrentPoints
    const setCurrentWallModel = useWallStore.getState().setCurrentWallModel
    
    setCurrentWallModel(wallURL)
    setCurrentPoints([])
}

export const handleUpdateCurrentGymWall = (_wallID, data) => {
    const setCurrentWallModel = useWallStore.getState().setCurrentWallModel
    const setCurrentWallSetID = useGymStore.getState().setCurrentWallSetID
    const setCurrentWallID = useGymStore.getState().setCurrentWallID

    //When switching the wall to render, in order to find the url to load the model
    // it must go to the currentWallSetID, find the wallSet, then load the wallSetID
    //because there is no wallURL anymore
    const wallIndex = data.walls.findIndex((wall) => wall._id == _wallID)
    setCurrentWallID(data.walls[wallIndex]._id)

    const wallSetID = data.walls[wallIndex].currentWallSetID
    setCurrentWallSetID(wallSetID)

    const wallSetIndex = data.walls[wallIndex].wallSets.findIndex((wallSet) => wallSet._id == wallSetID)
    setCurrentWallModel({ modelURL: data.walls[wallIndex].wallSets[wallSetIndex].wallSetURL,
                          geometry: data.walls[wallIndex].wallSets[wallSetIndex].wallGeometry,
                          material: data.walls[wallIndex].wallSets[wallSetIndex].wallMaterial })

}