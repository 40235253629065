import { Link } from 'react-router-dom'
import YoutubeLogo from '../assets/img/youtube-icon.png'
import { H4 } from '../blocks/components/typography/H4.js'
import { TextMedium } from '../blocks/components/typography/TextMedium.js'
import { TextSmall } from '../blocks/components/typography/TextSmall.js'


const Footer = () => {
    return (
        <div className="container max-w-full p-4 bg-indigo-100">
          <TextMedium text={"beta-break"} color={"text-indigo-950"} />
          <TextSmall text={"C 2024 beta-break. All rights reserved."} color={"text-neutral-950"} />

          <div className="my-12"></div>
          <TextMedium text={"Get in Touch"} color={"text-indigo-950"} />
          <TextSmall text={"support@beta-break.com"} color={"text-neutral-950"} />

          {/* <div className="my-12"></div>
          <TextMedium text={"Social Media"} color={"text-indigo-950"} />
          <div>
            <a href="https://www.youtube.com/@beta-break">
              <img src={YoutubeLogo} className="mt-1 h-6 w-6"></img>
            </a>
          </div> */}

          <div className="my-12"></div>
          <TextMedium text={"Learn More"} color={"text-indigo-950"} />
          <div className="flex-col">
            <Link to={{ pathname: "/about"}}>
              <TextSmall text={"About"} color={"text-neutral-950"} />
            </Link>
            <Link to={{ pathname: "/howitworks"}} className="footer-general-text footer-link">
              <TextSmall text={"How It Works"} color={"text-neutral-950"} />
            </Link>
            <Link to={{ pathname: "/contact-us"}} className="footer-general-text footer-link">
              <TextSmall text={"Contact Us"} color={"text-neutral-950"} />
            </Link>
            <Link to={{ pathname: "/blog"}} className="footer-general-text footer-link">
              <TextSmall text={"Blog"} color={"text-neutral-950"} />
            </Link>
            <Link to={{ pathname: "/policies"}} className="footer-general-text footer-link">
              <TextSmall text={"Policies"} color={"text-neutral-950"} />
            </Link>
          </div>
      </div>
    )
}

export default Footer